import React from 'react'
import wineglassescheers from '../assets/wineglassescheers.png'
import cheeseboard from '../assets/cheeseboard.png';
import { motion } from 'framer-motion';

const WineTasting = () => {
  return (
    <div id="winetasting" className="flex flex-col bottlesbg bg-repeat-y pb-6">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{delay: 0.25}} >
          <h2 className='italiana text-[30px] md:text-[50px] text-center mx-auto opacity-100 pt-14'>Wine Tasting</h2>
          <h2 className='italiana text-[50px] md:text-[90px] text-center mx-auto opacity-10 mt-[-65px] md:mt-[-115px]'>Wine Tasting</h2>
      </motion.div>

      <div className="md:px-12 lg:px-36">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <motion.div 
          initial={{ y: 50,opacity: 0 }}
          whileInView={{y: 0, opacity: 1 }}
          transition={{duration: 1}}
          className="p-4 md:w-[50%]">
            <p className="p-2">At wine tastings, guests have the opportunity to sample and learn about a variety of exceptional wines from around the world. </p>
            <p className="p-2">Led by myself, a certified wine connoisseur and expert, attendees will discover the unique characteristics and flavours of each wine, as well as gain insights into the history and production of each varietal.  </p>
          </motion.div>
          <motion.div 
          initial={{ y: 50,opacity: 0 }}
          whileInView={{y: 0, opacity: 1 }}
          transition={{duration: 1}}
          className="md:w-[50%] md:p-4 md:flex md:items-center md:justify-center">
            <img src={wineglassescheers} alt="wine glasses cheers" className="w-96"/>
          </motion.div>
        </div>
        <div className="flex flex-col-reverse items-center justify-center md:flex-row">
          <motion.div 
          initial={{ y: 50,opacity: 0 }}
          whileInView={{y: 0, opacity: 1 }}
          transition={{duration: 1}}
          className="md:w-[50%] md:p-4 md:flex md:items-center md:justify-center">
            <img src={cheeseboard} alt="cheeseboard" className="w-96"/>
          </motion.div>
          <motion.div 
          initial={{ y: 50,opacity: 0 }}
          whileInView={{y: 0, opacity: 1 }}
          transition={{duration: 1}}
          className="p-4 md:w-[50%]">
            <p className="p-2">With a relaxed and comfortable atmosphere, the events are the perfect opportunity to expand your appreciation for this timeless beverage</p>
            <p className="p-2">
          Whether you are a wine enthusiast looking to deepen your knowledge or simply seeking a sophisticated evening out, wine tastings are sure to delight and educate.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default WineTasting
