import Home from "./components/Home";
import About from "./components/About";
import WineTasting from "./components/WineTasting";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import NavBar from "./components/NavBar";

function App() {
  return (
    <div className="container mx-auto bg-gray-100 flex flex-col h-screen w-full">
      <NavBar />
      <Home />
      <About />
      <WineTasting />
      <Testimonials />
      <Contact />
    </div>
  );
}

export default App;
