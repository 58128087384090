import Carousel from "./Carousel.jsx"
import { motion } from "framer-motion";

const Testimonials = () => {
  return (
    <div id="testimonials" className="bg-black text-white flex flex-col pb-32 ">
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{delay: 0.25}} >
      
        <h2 className='italiana text-[40px] md:text-[50px] text-center mx-auto opacity-100 pt-14'>Testimonials</h2>
        <h2 className='italiana text-[70px] md:text-[90px] text-center mx-auto opacity-10 mt-[-90px] md:mt-[-115px]'>Testimonials</h2>
      </motion.div>
      <div className="h-[70%] mt-28">
        
        <Carousel/>
   
      </div>
      
    </div>
  )
}

export default Testimonials
