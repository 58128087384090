import React from 'react'

// Core modules imports are same as usual
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';




const Carousel = () => {
  return (
    <section>
      
        <Swiper
          effect={'coverflow'}
          grabCursor={ true }
          centeredSlides={ true }
          loop={true}
          slidesPerView={'auto'}
          coverflowEffect={
            {
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }
          }
          pagination={{el:'.swiper-pagination', clickable: true}}
          navigation={{
            nextEl:'.swiper-button-next',
            prevEl:'.swiper-button-prev',
            clickable: true
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className='swiper_container'
        >
          <SwiperSlide>
            <div className='bg-white text-black h-[350px] p-8 rounded-lg w-[330px] md:w-[400px] mx-auto text-center flex flex-col justify-around'>
              <p className="italic text-md font-light">"I attended a wine tasting evening and found Ovi very knowledgable and I was encouraged into trying the reds on offer when I ordinarily would only drink whites."</p>
              <div>
              <h3>Marie Chadwick, UK</h3>

              </div>
              
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='bg-white text-black h-[350px] p-8 rounded-lg w-[330px] md:w-[400px] mx-auto text-center flex flex-col justify-around'>
              <p className="italic text-md font-light">"We attended Ovi's first wine presentation evening & had a thoroughly enjoyable experience. Ovi is very knowledgable & chose some fabulous wines, accompanied by a nice selection of food. Would recommend Ovi's wine evenings."</p>
              <div>
              <h3>Gail Howard, UK</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='bg-white text-black h-[350px] p-8 rounded-lg w-[330px] md:w-[400px] mx-auto text-center flex flex-col justify-around text-sm'>
              <p className="italic text-md font-light">"We hosted Ovi's first wine tasting event at our house for friends and family. His attention to detail on both wine selection and accompanying food was excellent.</p>
              
              <p className="italic text-md font-light">The evening was a great success and we will be looking to repeat the event now we have completed our house improvements. Well done Ovidiu Szabo I am sure your reputation for providing excellent events will ensure an enthusiastic and growing clientele."</p>
              <div>
              <h3>Andrew Picton, Westhoughton, UK</h3>
              </div>
              
            </div>
          </SwiperSlide>


          <div className='slider-controller'>
            <div className='swiper-button-prev slider-arrow'>
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className='swiper-button-next slider-arrow'>
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className='swiper-pagination text-center'></div>
            
          </div>

         
            
         
        </Swiper>
        
      
      
    </section>
  )
}

export default Carousel