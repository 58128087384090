import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose, AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";

const NavBar = () => {
  let Links = [
    { name: "HOME" ,href: "#home"},
    { name: "ABOUT" ,href: "#about"},
    { name: "WINE TASTING" ,href: "#winetasting"},
    { name: "TESTIMONIALS" ,href: "#testimonials"},
    { name: "CONTACT" ,href: "#contact"} 
  ]
  let [open, setOpen] = useState(false);
  return (
    <motion.div 
        initial={{ y: -50,opacity: 0 }}
        animate={{y: 0, opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeOut"}}
    className="relative top-0 container  md:flex md:items-center md:flex-row-reverse lg:justify-center bg-white">
      <div
        onClick={() => setOpen(!open)}
        className="text-3xl cursor-pointer md:hidden flex justify-end py-8 pr-4"
      >
        {!open ? <RxHamburgerMenu /> : <AiOutlineClose />}
      </div>

      <ul
        className={`md:flex md:items-center font-[Italiana] py-6 bg-white  md:z-auto z-10 left-0 w-full md:w-auto md:pl-0 transition-all duration-500 ease-in text-center absolute md:static ${
          open ? "top-20 opacity-100 " : "top-[-550px]"
        } md:opacity-100 opacity-0`}
      >
        {Links.map((link) => (
          <li key={link.name} className="md:ml-8 text-xl md:my-0 my-7">
            <AnchorLink
              href={link.href}
              className="hover:text-gray-700 hover:underline hover:transition-all hover: ease-in"target={link.href}
            >
              {link.name}
        
            </AnchorLink>
          </li>
        ))}
      </ul>

      <div className="flex items-center justify-center absolute top-7 left-6  md:top-4 md:left-0 lg:top-4 lg:left-24">
        <a href="https://www.instagram.com/ovinowine">
          <AiOutlineInstagram size={40} className="hover:text-[#d5723cf4]" target="_blank"/>
        </a>
        <a href="https://www.facebook.com/ovinowines">
          <AiOutlineFacebook size={38} className="hover:text-blue-300" target="_blank"/>
        </a>
      </div>
    </motion.div>
  );
};

export default NavBar;
