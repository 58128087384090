import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai';
import { motion } from 'framer-motion';

const Contact = () => {
  const [status, setStatus] =  useState('')
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5wkc6pp', 'template_a9ajdxn', form.current, 'Ew3Elm4BhrPmk5NXG')
      .then((result) => {
          console.log(result.text, result.status);
          form.current.reset();
          setStatus('Success!')
      }, (error) => {
          console.log(error.text);
          
      });
  };

  const renderAlert = () => {
    
        return (
          <p className='py-3 italic'>Your message was sent successfully!</p>
        )
  }

  return (
    <motion.div
    initial={{ y: 50,opacity: 0 }}
          whileInView={{y: 0, opacity: 1 }}
          transition={{duration: 1}}
    id="contact" className="bg-white text-black flex flex-col md:min-h-screen ">

      <h2 className='italiana text-[40px] md:text-[50px] text-center mx-auto opacity-100 pt-14'>Contact</h2>
      <h2 className='italiana text-[70px] md:text-[90px] text-center mx-auto opacity-10 mt-[-90px] md:mt-[-115px]'>Contact</h2>

      <div className='border border-gray-100 p-4 m-4 grid lg:grid-cols-2 gap-4 shadow-md'>

        <div className='border border-gray-50 max-w-[80%] mx-auto flex flex-col justify-center p-4'>
          <div className='lg:my-8'>
            <p className='my-4 text-center'>Are you are interested in a wine tasting or have any questions?</p>

            <p className='my-4 text-center'>Please fill out the form below with your name, email address, and a brief message and I will be in touch.</p>

            <p className='my-4 text-center'>Also feel free to visit my social media pages.</p>
          </div>

          <div className='flex items-center justify-center gap-4 p-4 lg:my-8'>
            <a href="https://www.instagram.com/ovinowine" target='_blank' className='cursor-pointer'><AiOutlineInstagram size={50} className='hover:text-[#d5723cf4]'/></a>
            <a href="https://www.facebook.com/ovinowines" target="_blank">
            <AiOutlineFacebook size={48} className='hover:text-blue-300'/></a>
          </div>

        </div>

        <div>

          
          
          <form ref={form} onSubmit={sendEmail} className='flex flex-col max-w-[80%] mx-auto'>
            <label className='mb-2 italiana text-xl'>Name</label>
            <input type="text" name="user_name" className='shadow-lg p-2 cursor-text mb-4 bg-gray-50 border-2 border-black' />
            <label className='mb-2 italiana text-xl'>Email</label>
            <input type="email" name="user_email" className='shadow-lg p-2 cursor-text mb-4 bg-gray-50 border-2 border-black' />
            <label className='mb-2 italiana text-xl'>Message</label>
            <textarea name="message" className='shadow-lg p-2 cursor-text mb-4 bg-gray-50 border-2 border-black' rows={8}/>
            <div>
              {status && renderAlert()}
            </div>
            <input type="submit" value="Send" className='cursor-pointer shadow-md bg-black text-white p-2 transition ease-in-out delay-100 hover:shadow-xl hover:scale-[105%]'/>
          </form>
          
        </div>
      </div>
    </motion.div>
  

  
  );
};

export default Contact;
