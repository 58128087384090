import React from "react";
import wineGlasses from "../assets/wineglasses.png";
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <div id="home" className="flex flex-col min-h-screen items-center justify-center whitebg ">
      <div className="flex flex-col items-center justify-between">
        <motion.h1 
        
        initial={{ x: 500, opacity: 0, scale: 0 }}
        animate={{ x: 0, opacity: 1, scale: 1.5 }}
        transition={{ duration: 2 }}
       
        className="italiana text-6xl uppercase opacity-100">
          <span className="text-8xl">O</span>vino
        </motion.h1>
        <motion.h2 
        initial={{ opacity: 0,x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, delay: 2}}
        
        className=" mb-[-10px] mt-[5px] px-2 ml-10 text-black font-thin text-sm italiana">
          Private Wine Tasting
        </motion.h2>
        <motion.img
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2 }}
          src={wineGlasses}
          alt="Animated picture of wine glasses"
          className="w-[320px] mt-[-80px]"
        />
      </div>
      <p className="italic font-extralight italiana text-sm w-[270px] text-center">
        "Wine is the most civilised thing in the world" - Ernest Hemingway
      </p>
    </div>
  );
};

export default Home;
