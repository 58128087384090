import React from 'react'
import Ovi from "../assets/Ovi.png"
import { motion } from 'framer-motion'

const About = () => {
  

  return (
    <div id="about" className="bg-black text-white flex flex-col lg:min-h-screen ">

      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{delay: 0.25}}
      >
        <h2 className='italiana text-[40px] md:text-[50px] text-center mx-auto opacity-100 pt-14'>About Me</h2>
        <h2 className='italiana text-[70px] md:text-[90px] text-center mx-auto opacity-10 mt-[-90px] md:mt-[-115px]'>About Me</h2>
      </motion.div>
      <div className="flex flex-col md:flex-row-reverse md:px-12 md:mt-16 lg:px-36">
        <motion.div
        initial={{ x: 50,opacity: 0 }}
        whileInView={{x: 0, opacity: 1 }}
        transition={{duration: 1}}
        
        className='md:w-[50%] flex items-center justify-center'>
          <img src={Ovi} alt="picture of Ovidiu Szabo" className='w-72 md:w-80 mx-auto'/>
        </motion.div>
      
      <motion.div 
      initial={{ x: -50,opacity: 0 }}
      whileInView={{x: 0, opacity: 1 }}
      transition={{duration: 1}}
      className='p-4 md:w-[50%]'>
        <p className='p-2'>Hi, my name is <strong>Ovidiu Szabo.</strong></p>
        <p className='p-2'>As a wine connoisseur and tasting event organiser, I am dedicated to discovering and sharing the finest wines from around the world. </p>
        <p className='p-2'>With a refined palate and deep understanding of the complexities and nuances of different wine varietals, I have developed a reputation as a trusted resource for those looking to learn more about wine. </p>
        <p className='p-2'>Whether hosting a wine tasting event or sharing recommendations with friends and colleagues, I am always eager to share my love and knowledge of wine. Through my passion and expertise. </p>
      </motion.div>
      </div>
    </div>
  )
}

export default About
